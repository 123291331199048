<template>
	<div class="padding-top-10 edit-contain">
		<top :bgWhite='true'></top>

		<div class="contents  padding-bottom-20 ">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/my' }">个人中心</el-breadcrumb-item>
				<el-breadcrumb-item>我的资料</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="bg-white margin-top-20 padding-lr-50 padding-bottom-20">
				<div class="solid-bottom padding-tb-10 flex align-center justify-between">
					<p class="fs14 text-333">头像</p>
					<el-upload class="avatar-uploader" :action="BaseUrl+'/api/v1/5d5fa8984f0c2'" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="form.head_img" :src="form.head_img" class="block-88 radius-88">
						<div class="dafault-box" v-else>
							<img  src="../../../assets/image/home/head.png" class="block-88">
							<div class="change_mask">
								更换
							</div>
						</div>
					</el-upload>
				</div>
				<div class="solid-bottom padding-tb-10 flex align-center justify-between">
					<p class="fs14 text-333">作家简介照片</p>
					<el-upload class="avatar-uploader" :action="BaseUrl+'/api/v1/5d5fa8984f0c2'" :show-file-list="false"
						:on-success="handleIntroSuccess" :before-upload="beforeAvatarUpload">
						<el-image v-if="intro" :src="intro" class="block-88" fit="cover"></el-image>
						<div class="dafault-box" v-else>
							<img  src="../../../assets/image/home/head.png" class="block-88">
							<div class="change_mask">
								更换
							</div>
						</div>
					</el-upload>
				</div>
				<div class="solid-bottom padding-tb-10 flex align-center justify-between">
					<p class="fs14 text-333">昵称 </p>
					<div class="text-right">
						<el-input type="text" @blur="nameBlur" maxlength="7"  class="w60 text-right text-black" v-model="form.user_nickname" placeholder="请输入昵称">
						</el-input>
					</div>
				</div>
				<div class="solid-bottom padding-tb-10 flex align-center justify-between">
					<p class="fs14 text-333">手机号 </p>
					<div class="text-right">
						<el-input type="text" maxlength="11" disabled class="w60 text-right text-black" v-model="form.mobile" placeholder="请输入手机号">
						</el-input>
					</div>
				</div>
				<div class="solid-bottom padding-tb-10 flex align-center justify-between">
					<p class="fs14 text-333">性别 </p>
					<el-select class="" v-model="sexTxt" placeholder="请选择性别" style="width: 5%;" @change="sexChanged">
						<el-option label="男" value="1"></el-option>
						<el-option label="女" value="2"></el-option>
					</el-select>
				</div>
				<div class="solid-bottom padding-tb-10 flex align-center justify-between">
					<p class="fs14 text-333">生日 </p>
					<div class="point text-right" style="width: 10%;">
						<el-date-picker class="point text-right" type="date" placeholder="选择日期"
						 v-model="form.birthday" @change="selectTime" value-format="yyyy-MM-dd"
							style="width: 100%;"></el-date-picker>
					</div>
				</div>
				<div class="solid-bottom padding-tb-10 flex align-center justify-between">
					<p class="fs14 text-333">所在地 </p>
					<el-cascader :options="areaSelectData" :disabled="isNotEdit" @change="handleChange"
						class="full-width" size="large" v-model="selectedOptions" placeholder="请选择您所在的城市" />
				</div>
				<div class="solid-bottom padding-tb-10 flex align-center justify-between">
					<p class="fs14 text-333">民族 </p>
					<div class="text-right">
						<el-input type="text" @blur="nameBlur" maxlength="7"  class="w60 text-right text-black" v-model="form.nationality" placeholder="请输入民族">
						</el-input>
					</div>
				</div>
				<div class="solid-bottom padding-tb-10 flex align-center justify-between">
					<p class="fs14 text-333">国籍 </p>
					<div class="text-right">
						<el-input type="text" @blur="nameBlur" maxlength="7"  class="w60 text-right text-black" v-model="form.citizenship" placeholder="请输入国籍">
						</el-input>
					</div>
				</div>
				<div class="solid-bottom padding-tb-10 flex align-center justify-between">
					<p class="fs14 text-333">籍贯 </p>
					<div class="text-right">
						<el-input type="text" @blur="nameBlur" maxlength="7"  class="w60 text-right text-black" v-model="form.native_place" placeholder="请输入籍贯">
						</el-input>
					</div>
				</div>
				<div class="padding-tb-10">
					<p class="fs18  text-333">个人简介 </p>
					<div class="margin-top-20">
						<el-input type="textarea" show-word-limit  maxlength="200" @blur="introBlur" rows="6" placeholder="请输入..." v-model="form.autograph"></el-input>
					</div>
				</div>
				<!-- <div class="margin-top">
					<el-button type="danger" round @click="submitForm">保存</el-button>
				</div> -->
			</div>
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import {
		quillEditor
	} from 'vue-quill-editor'
	import {
		provinceAndCityData,
		regionData,
		provinceAndCityDataPlus,
		regionDataPlus,
		CodeToText,
		TextToCode
	} from 'element-china-area-data'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		name: 'editArticle',
		components: {
			bottom,
			top,
			quillEditor
		},
		data() {
			return {
				avatar:'', //头像
				intro:'', //作家照片
				user_id:'', //用户id
				sexTxt:'男',
				isNotEdit:false,
				areaSelectData: regionData, // options绑定的数据就是引入的 provinceAndCityData
				selectedOptions: [], // 地区选择参数，['省区域码', '市区域码']
				nickname: '', //昵称
				mobile: '', //手机号
				BaseUrl: this.imgUrl,
				type: 1, //1  动态  2 文章   3电子书
				content: '', //富文本内容
				editorOption: {},
				imageUrl: '', //封面
				form: {
					photo:'',//作家照片
					sex: 0 ,//性别
					autograph:'' ,//个人简介
					user_nickname:'', //用户昵称
					birthday:'', //生日
					address:'', //地址
					head_img:'', //头像
					nationality:'', //民族
					citizenship:'',//国籍
					native_place:'' //籍贯
				}
			}
			// editorOption里是放图片上传配置参数用的，例如：
			// action:  '/api/product/richtext_img_upload.do',  // 必填参数 图片上传地址
			// methods: 'post',  // 必填参数 图片上传方式
			// token: '',  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
			// name: 'upload_file',  // 必填参数 文件的参数名
			// size: 500,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
			// accept: 'multipart/form-data, image/png, image/gif, image/jpeg, image/bmp, image/x-icon,image/jpg'  // 可选 可上传的图片格式
		},
		mounted() {
			this.getUserInfo()
		},
		created() {
			
		},
		methods: {
			// 上传成功回调
			handleAvatarSuccess(res, file) {
				this.avatar = URL.createObjectURL(file.raw);
				this.form.head_img = res.data[0].path;
				let data = {
					head_img:this.form.head_img
				}
				this.editFun(data)
			},
			// 上传作家简介成功回调
			handleIntroSuccess(res, file) {
				this.intro = URL.createObjectURL(file.raw);
				this.form.photo = res.data[0].path;
				let data = {
					photo:this.form.photo
				}
				this.editFun(data)
			},
			// 昵称失去焦点
			nameBlur(){
				let data = {
					user_nickname:this.form.user_nickname
				}
				this.editFun(data)
			},
			// 性别选择
			sexChanged(e){
				let data = {
					sex:this.form.sex
				}
				this.editFun(data)
			},
			// 选择生日
			selectTime(e){
				let data = {
					birthday:this.form.birthday
				}
				this.editFun(data)
			},
			// 上传封面
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt10M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt10M) {
					this.$message.error('上传头像图片大小不能超过 10MB!');
				}
				return isLt10M;
			},
			// 保存修改
			// submitForm() {
			// 	let data = this.form;
			// 	this.ajax('post', '/v1/5cb54af125f1c', data, res => {
			// 		if (res.code == 1) {
			// 			this.$message.success('修改成功');
			// 		} else {
			// 			this.$message.error(res.msg);
			// 		}
			// 	})
			// },
			// 修改方法
			editFun(data){
				this.ajax('post', '/v1/5cb54af125f1c', data, res => {
					if (res.code == 1) {
						this.$message.success('修改成功');
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 编辑格式化地址
			handleChange(e) {
				console.log(e);
			    let codeStr = e.join(',');
				let address = this.getCodeToText(codeStr,e);
				let data = {
					address:address,
					address_code:JSON.stringify(e)
				}
				this.editFun(data)
			},
			// 简介修改
			introBlur(){
				let data = {
					autograph:this.form.autograph
				}
				this.editFun(data)
			},
			// 获取个人信息
			getUserInfo() {
				let userinfo = JSON.parse(localStorage.getItem('USER_INFO'))
				this.ajax('post', '/v1/5c78c4772da97', {
					user_id:userinfo.id || ''
				}, res => {
					if (res.code == 1) {
						this.form = res.data;
						this.intro = res.data.photo;
						this.selectedOptions = JSON.parse(res.data.address_code);
						// todo
						this.user_id = res.data.user_id;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 地址解码
			getCodeToText(codeStr, codeArray) {
			  if (null === codeStr && null === codeArray) {
				return null;
			  } else if (null === codeArray) {
				codeArray = codeStr.split(",");
			  }
					
			  let area = "";
			  switch (codeArray.length) {
				case 1:
				  area += CodeToText[codeArray[0]];
				  break;
				case 2:
				  area += CodeToText[codeArray[0]] + "/" + CodeToText[codeArray[1]];
				  break;
				case 3:
				  area +=
					CodeToText[codeArray[0]] +
					"-" +
					CodeToText[codeArray[1]] +
					"-" +
					CodeToText[codeArray[2]];
				  break;
				default:
				  break;
			  }
			  return area;
			}
		}
	}
</script>

<style scoped>
	.edit-contain {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}

	.contents {
		width: 80%;
		height: auto;
		margin: 80px auto;
	}
	/deep/.el-input__inner {
		border: none;
		text-align: right;
	}
	/deep/.el-input.is-disabled .el-input__inner{
		background: none;
	}
	.dafault-box{
		position: relative;
	}
	.change_mask{
		position: absolute;
		background: rgba(0,0,0,0.2);
		color: #FFFFFF;
		bottom: 0;
		left: 0;
		width: 88px;
		height: 44px;
		border-radius: 0 0 88px 88px;
	}
	.myQuillEditor {
		height: 400px;
	}

	/deep/.el-form-item__label {
		padding-right: 30px;
	}
	/deep/.el-input__inner::-webkit-inner-spin-button{ 
	    -webkit-appearance: none !important;    
	    margin: 0;    
	    -moz-appearance: textfield; 
	}
	/deep/.el-radio__inner {
		width: 20px;
		height: 20px;
	}

	.el-form-item {
		display: flex;
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		border-color: #E54F42;
		background: #E54F42;
	}

	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #333333;
	}
</style>
